<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="row">
        <div class="col">
          <p class="dialog-title mb-0">Friend Request</p>
        </div>
      </div>
    </div>
    <div class="dialog-content"
         style="overflow-y: auto; min-height: 20rem;">
      <div class="mb-3">
        <p class="medium">You are about to send a Safe Circle request.
          Pick a category for this user.</p>
        <v-select class="category-selector"
                  placeholder="Select a Category"
                  v-model="selectedGrouping"
                  :options="groupings"
        ></v-select>
      </div>

      <!-- Buttons -->
      <div class="text-center pt-3">
        <b-button class="btn btn-success btn-h3-small"
                  type="button"
                  @click="sendFriendRequest">
          Send
        </b-button>
        <b-button class="btn btn-danger btn-h3-small"
                  type="button"
                  @click="removeInviteParams">
          Cancel
        </b-button>
      </div>
    </div>

    <!-- Loading -->
    <loading :active.sync="isLoading"
              :is-full-page="true"
              background-color="#000"
              color="#068bcd"
              :opacity="0.5"
              :width="70"
              :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');

  import _ from 'lodash';

  export default {
    name  : 'SCGrouping',
    props : {
      friendChatId   : String,
      friendUserType : String,
    },
    data() {
      return {
        isLoading        : false,
        selectedGrouping : null,
      }
    },
    computed : {
      groupings() {
        let groupings = [
          'Friends',
          'Family',
        ];

        if (this.friendUserType === '3') {
          groupings = [
            'Friends',
            'Family',
            'Educators',
          ];
        }

        return groupings;
      },
    },
    components : {
      Loading,
      RainbowLoader,
    },
    methods : {

      /**
       * Send Friend Request
       */
      sendFriendRequest() {
        this.isLoading = true;
        this.$http.post('api/safecircle/friend', {
          friendChatId : this.friendChatId,
          SCGrouping   : _.toLower(this.selectedGrouping),
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Friend request sent',
          });
          this.removeInviteParams();
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops Something Went Wrong!. Please try again later.',
          });
        }).finally(() => {
          this.isLoading = false;
        });
      },

      /**
       * Remove Invite Params
       */
      removeInviteParams() {
        localStorage.removeItem('friendChatId');
        localStorage.removeItem('friendUserType');
        this.$emit('close');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/sc-grouping";
</style>